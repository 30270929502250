<template>
  <div class="elite-tabs-wrapper-content">
    <section class="user-security">
      <h3>{{ $t("PROFILE.YOUR_SECURITY") }}</h3>
      <ul class="list-group">
        <li
          v-if="!user.is_2fa_enabled"
          class="list-group-item d-flex justify-content-between align-content-center"
        >
          <button @click="enable2FA" type="button" class="btn btn-sm btn-dark">
            {{ $t("PROFILE.ENABLE_2FA") }}
          </button>
          <span class="d-flex align-content-center">
            <span v-if="user.two_fa_disabled_at">
              {{ $t("PROFILE.DISABLE_2FA_AT") }}
              {{ $formatDate(user.two_fa_disabled_at, "DD MMM YYYY HH:mm") }}
            </span>
          </span>
        </li>
        <li
          v-if="user.is_2fa_enabled"
          class="list-group-item d-flex justify-content-between align-content-center"
        >
          <button @click="disable2FA" type="button" class="btn btn-sm btn-dark">
            {{ $t("PROFILE.DISABLE_2FA") }}
          </button>
          <span class="d-flex align-content-center">
            <span v-if="user.two_fa_enabled_at">
              {{ $t("PROFILE.ENABLE_2FA_AT") }}
              {{ $formatDate(user.two_fa_enabled_at, "DD MMM YYYY HH:mm") }}
            </span>
          </span>
        </li>
      </ul>
    </section>
  </div>
</template>
<script>
import { Option, Select } from "element-ui";
import swal from "sweetalert2";
import formMixin from "@/mixins/form-mixin";
import BaseSwitch from "@/components/Inputs/BaseSwitch.vue";

export default {
  layout: "DashboardLayout",

  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    BaseSwitch,
  },

  mixins: [formMixin],

  props: ["user"],

  data() {
    return {
      is_2fa_enabled: !!this.user.is_2fa_enabled,
    };
  },

  mounted() {
    this.enableTwoFa();
  },

  methods: {
    async enable2FA() {
      this.user.is_2fa_enabled = !!this.user?.is_2fa_enabled;
      const confirmation = await swal.fire({
        title: this.$t(
          `COMMON.${this.user.is_2fa_enabled ? "DISABLE_2FA" : "ENABLE_2FA"}`
        ),
        type: "question",
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: this.$t("COMMON.YES"),
        cancelButtonText: this.$t("COMMON.NO"),
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });

      if (!confirmation?.isConfirmed) {
        return;
      }

      try {
        await this.$store.dispatch("profile/twoFaRequest", {});
        this.$notify({
          type: "success",
          message: this.$t(`PROFILE.EMAIL_IS_SENT_TO_ENABLE_2FA`),
        });
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.formErrors = error.response.data.errors;
      }
    },

    async disable2FA() {
      const confirmation = await swal.fire({
        title: this.$t(
          `COMMON.${this.user.is_2fa_enabled ? "DISABLE_2FA" : "ENABLE_2FA"}`
        ),
        type: "question",
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: this.$t("COMMON.YES"),
        cancelButtonText: this.$t("COMMON.NO"),
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });

      if (!confirmation) {
        return;
      }

      try {
        await this.$store.dispatch("profile/deactivate2FA", {});
        this.$notify({
          type: "success",
          message: this.$t(`PROFILE.DISABLE_2FA_SUCCESS`),
        });
        this.$emit("onUserUpdated");
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.formErrors = error.response.data.errors;
      }
    },

    async enableTwoFa() {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const action = urlParams.get("action");
      const token = urlParams.get("token");
      if (action && action === "activation_2fa" && token) {
        try {
          await this.$store.dispatch("profile/activate2FA", {
            token,
          });
          this.$notify({
            type: "success",
            message: this.$t(`PROFILE.ENABLE_2FA_SUCCESS`),
          });
          await this.$store.dispatch("logout");
        } catch (error) {
          this.$notify({
            type: "danger",
            message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
          });
          this.formErrors = error.response.data.errors;
        }
      }
    },
  },

  created() {},
};
</script>
