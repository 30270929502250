<template>
  <div>
    <div class="page-wrapper-table">
      <div class="page-wrapper-table-body">
        <div class="page-wrapper-table-body-inner">
          <el-table
            class="align-items-center table-flush"
            header-row-class-name="thead-light"
            :data="locations"
          >
            <div class="table-loading" slot="empty" v-if="loading">
              <img src="/img/loading.gif" />
            </div>
            <el-table-column
              :label="$t('COMMON.NAME')"
              prop="name"
              sortable="custom"
              min-width="220"
            />
            <el-table-column
              v-if="$currentUserCan($permissions.PERM_VIEW_ANY_USERS)"
              :label="$t('COMMON.MANAGER')"
            >
              <template v-slot="{ row }">
                <users :users="row?.manager?.id ? [row.manager] : []" />
              </template>
            </el-table-column>
            <el-table-column
              :label="$t('COMMON.CREATED_AT')"
              prop="created_at"
              sortable="custom"
              min-width="220"
            >
              <template v-slot="{ row }">
                {{ $formatDate(row.created_at) }}
              </template>
            </el-table-column>

            <el-table-column
              :label="$t('COMMON.UPDATED_AT')"
              prop="created_at"
              sortable="custom"
              min-width="220"
            >
              <template v-slot="{ row }">
                {{ $formatDate(row.updated_at) }}
              </template>
            </el-table-column>
            <el-table-column
              v-if="$currentUserCan($permissions.PERM_EDIT_HIS_LOCATIONS)"
              fixed="right"
              min-width="120"
            >
              <div slot-scope="{ row }" class="table-actions">
                <el-tooltip :content="$t('COMMON.EDIT')" placement="top">
                  <a
                    type="text"
                    class="table-action"
                    data-toggle="tooltip"
                    @click="onEditLocation(row)"
                    v-if="$currentUserCan($permissions.PERM_EDIT_HIS_LOCATIONS)"
                    style="cursor: pointer"
                  >
                    <i class="fal fa-edit"></i>
                  </a>
                </el-tooltip>
              </div>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>

    <modal :show="showModal" modal-classes="modal-secondary" size="lg">
      <div class="" slot="header">
        <h2 id="">
          {{ $t("LOCATIONS.EDIT_LOCATION") }}
        </h2>
      </div>
      <div class=""></div>

      <user-location-form
        v-if="location"
        :loading="loading"
        :locationData="location"
        :formErrors="formErrors"
        @locationSubmitted="handleSubmit"
        :closeForm="toggleModalState"
        @formChanged="() => (alertLeave = true)"
      />
    </modal>
  </div>
</template>
<script>
import { cloneDeep } from "lodash";
import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  Option,
  Select,
  Table,
  TableColumn,
  Tooltip,
} from "element-ui";
import Users from "@/components/Users.vue";
import UserLocationForm from "./UserLocationForm.vue";
import alertLeave from "@/mixins/alert-leave-mixin";
import requestErrorMixin from "@/mixins/request-error-mixin";

export default {
  name: "user-location-list-table",

  components: {
    UserLocationForm,
    Users,
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
  },

  mixins: [alertLeave, requestErrorMixin],

  props: {
    filterOrganization: {
      type: String,
      default: null,
      description: "Organization id",
    },
    locations: {
      type: Array,
      default: () => [],
      description: "User locations",
    },
  },

  data() {
    return {
      loading: false,
      location: null,
      showModal: false,
      alertLeave,
      formErrors: null,
    };
  },

  computed: {},

  watch: {},

  methods: {
    toggleModalState() {
      this.showModal = !this.showModal;
    },

    onEditLocation(location) {
      this.location = {
        ...location,
        manager: location?.manager?.id
          ? location?.manager
          : {
              type: "users",
              id: null,
            },
        organization: location?.organization?.id
          ? organization?.organization
          : {
              type: "organizations",
              id: null,
            },
      };
      this.toggleModalState();
    },

    async handleSubmit(location) {
      this.loading = true;
      const locationData = cloneDeep(location);
      try {
        await this.$store.dispatch("locations/update", locationData);
        this.$notify({
          type: "success",
          message: this.$t("LOCATIONS.LOCATIONS_UPDATED"),
        });
        this.$emit("onUserUpdated");
        this.loading = false;
        this.toggleModalState();
      } catch (error) {
        if (error?.response?.status === 403) {
          await this.showRequestError(error);
        } else {
          this.$notify({
            type: "danger",
            message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
          });
          this.formErrors = error.response.data.errors;
        }
        this.loading = false;
      }
    },
  },
};
</script>
